<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Cabang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formBranch.name"
                class="uk-input"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama {{ formBranch.staff_title }}
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formBranch.data_analyst_name"
                class="uk-input"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Title Cabang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formBranch.head_title"
                class="uk-input"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Title Cabang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formBranch.staff_title"
                class="uk-input"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama {{ formBranch.head_title }}
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formBranch.branch_head_name"
                class="uk-input"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Status
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <LabelStatus :status="formBranch.status" />
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Terakhir Diubah
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formBranch.updated_at"
                class="uk-input"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Diubah Oleh
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formBranch.updated_by"
                class="uk-input"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="uk-width-1-1">
          <div
            class="uk-grid-small uk-child-width-1-3@xl uk-child-width-1-3@l uk-child-width-1-2@m uk-child-width-1-2@s  uk-child-width-1-1 uk-flex-right"
            uk-grid
          >
            <div class="uk-margin-bottom">
              <div class="upload-card">
                <div class="uk-width-1-1 uk-text-center uk-margin-small-bottom text-medium">
                  {{ formBranch.head_title }}
                </div>
                <div
                  class="uk-width-1-1 uk-text-center uk-margin-small-bottom js-upload"
                  uk-form-custom
                >
                  <img
                    v-lazy="formBranch.branch_head_sign ? `${image_url}${formBranch.branch_head_sign}` : `${images}/img-not-found.png`"
                    class="preview-image"
                    alt="sign kepcab"
                    uk-img
                  >
                </div>
                <div class="uk-width-1-1 uk-text-center uk-margin-auto text-medium truncate">
                  {{ formBranch.branch_head_name }}
                </div>
              </div>
            </div>
            <div class="uk-margin-bottom">
              <div class="upload-card">
                <div class="uk-width-1-1 uk-text-center uk-margin-small-bottom text-medium">
                  {{ formBranch.staff_title }}
                </div>
                <div
                  class="uk-width-1-1 uk-text-center uk-margin-small-bottom js-upload"
                  uk-form-custom
                >
                  <img
                    v-lazy="formBranch.data_analyst_sign ? `${image_url}${formBranch.data_analyst_sign}` : `${images}/img-not-found.png`"
                    class="preview-image"
                    alt="sign analist"
                    uk-img
                  >
                </div>
                <div class="uk-width-1-1 uk-text-center uk-margin-auto text-medium truncate">
                  {{ formBranch.data_analyst_name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-text-right">
          <router-link
            class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
            to="/admin/cabang"
          >
            <img
              v-lazy="`${images}/icon/back.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Kembali 
          </router-link>
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToEdit"
          >
            <img
              v-lazy="`${images}/icon/edit.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Ubah
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import LabelStatus from '@/components/globals/LabelStatus'
import { dateParanjeCompleteString } from '@/utils/formater'

export default {
  components: {
    LabelStatus
  },
  props: {
    dataBranch: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      image_url: process.env.VUE_APP_IMAGE_URL,
      isLoading: false,
      formBranch: {}
    }
  },
  watch: {
    async dataBranch() {
      if (this.dataBranch) {
        this.formBranch = {
          id: this.dataBranch.id || '',
          name: this.dataBranch.name || '-',
          status: this.dataBranch.status ? true : false,
          branch_head_name: this.dataBranch.branch_head_name || '-',
          data_analyst_name: this.dataBranch.data_analyst_name || '-',
          updated_at: dateParanjeCompleteString(this.dataBranch.updated_at) || '-',
          updated_by: this.dataBranch.updated_by || '-',
          data_analyst_sign: this.dataBranch.data_analyst_sign || '',
          branch_head_sign: this.dataBranch.branch_head_sign || '',
          head_title: this.dataBranch.head_title || '-',
          staff_title: this.dataBranch.staff_title || '-'
        }
      }
    }
  },
  methods: {
    goToEdit() {
      this.$router.push(`/admin/cabang/edit/${this.dataBranch.id}`)
    }
  }
}
</script>

<style scoped>

  .preview-image {
    width: auto;
    height: 150px;
    object-fit: cover;
  }

</style>
